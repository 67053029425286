import { ReserveStatus, LotActivityStatus } from './app/core/constants'
import { LotType } from './app/core/constants/lot-type'

export const siteHeader = {
    homePageButton: 'Home Page'
}

export const auction = {
    header: {
        lotNo: 'Lot %lotNo%',
        penNo: 'Pen %lotNo%',
        pickNo: 'Pick %lotNo%',
        runoutNo: 'Runout %lotNo%',
        lotExternalLinkText: 'See lot info',
        auctionWelcome: 'Welcome to bidr auction %auctionNo%',
        startingSoon: 'Auction will start at %auctionStartsAt%',
        auctionClosed: 'Auction has now closed',
        auctionFinished: 'Auction has now finished',
        auctionClosedButton: 'Return to home page'
    },
    status: {
        [LotActivityStatus.Inactive]: 'Inactive',
        [LotActivityStatus.Active]: 'Active',
        [LotActivityStatus.Withdrawn]: 'Withdrawn',
        [LotActivityStatus.Removed]: 'Removed',
        [LotActivityStatus.Sold]: 'Lot sold',
        [LotActivityStatus.SoldPostAuction]: 'Lot sold post auction',
        [LotActivityStatus.PassedIn]: 'Lot passed in',
        [LotActivityStatus.NotSold]: 'Lot not sold',
        [LotActivityStatus.Started]: 'Starting in %auctionTime%',
        [LotActivityStatus.NowSelling]: 'Now selling',
        [LotActivityStatus.Paused]: 'Paused',
        [LotActivityStatus.ResumeDelayed]: 'Starting in %auctionTime%',
        [LotActivityStatus.FinishDelayed]: 'Finishing',
        [LotActivityStatus.StartDelayPaused]: 'Paused',
        [LotActivityStatus.WithdrawnNotSold]: 'Withdrawn not sold',
        [LotActivityStatus.OfferManagement]: 'Pending',
        [LotActivityStatus.UnderOffer]: 'Pending',
        soldToBidder: 'Sold to you',
        postAuctionSoldToBidder: 'Sold to you post auction',
        startingSoon: 'Starting Soon',
        mobile: {
            [LotActivityStatus.Inactive]: 'Inactive',
            [LotActivityStatus.Active]: 'Active',
            [LotActivityStatus.Withdrawn]: 'Withdrawn',
            [LotActivityStatus.Removed]: 'Removed',
            [LotActivityStatus.Sold]: 'Sold',
            [LotActivityStatus.SoldPostAuction]: 'Lot sold',
            [LotActivityStatus.PassedIn]: 'Passed in',
            [LotActivityStatus.NotSold]: 'Not sold',
            [LotActivityStatus.Started]: 'Starting in %auctionTime%',
            [LotActivityStatus.NowSelling]: 'Selling',
            [LotActivityStatus.Paused]: 'Paused',
            [LotActivityStatus.ResumeDelayed]: 'Starting in %auctionTime%',
            [LotActivityStatus.FinishDelayed]: 'Finishing',
            [LotActivityStatus.StartDelayPaused]: 'Paused',
            [LotActivityStatus.WithdrawnNotSold]: 'Withdrawn',
            [LotActivityStatus.OfferManagement]: 'Pending',
            [LotActivityStatus.UnderOffer]: 'Pending',
            soldToBidder: 'Sold to you',
            startingSoon: 'Starting Soon',
            postAuctionSoldToBidder: 'Sold to you post auction'
        },
        ['lotType' + LotType.Pick]: {
            [LotActivityStatus.Inactive]: 'Inactive',
            [LotActivityStatus.Active]: 'Active',
            [LotActivityStatus.Withdrawn]: 'Withdrawn',
            [LotActivityStatus.Removed]: 'Removed',
            [LotActivityStatus.Sold]: 'Lot sold',
            [LotActivityStatus.SoldPostAuction]: 'Lot sold post auction',
            [LotActivityStatus.PassedIn]: 'Lot passed in',
            [LotActivityStatus.NotSold]: 'Lot not sold',
            [LotActivityStatus.Started]: 'Starting in %auctionTime%',
            [LotActivityStatus.NowSelling]: 'Now selling',
            [LotActivityStatus.Paused]: 'Paused',
            [LotActivityStatus.ResumeDelayed]: 'Starting in %auctionTime%',
            [LotActivityStatus.FinishDelayed]: 'Finishing',
            [LotActivityStatus.StartDelayPaused]: 'Paused',
            [LotActivityStatus.WithdrawnNotSold]: 'Withdrawn not sold',
            [LotActivityStatus.OfferManagement]: 'Pending Pick',
            [LotActivityStatus.UnderOffer]: 'Pending Pick',
            mobile: {
                [LotActivityStatus.Inactive]: 'Inactive',
                [LotActivityStatus.Active]: 'Active',
                [LotActivityStatus.Withdrawn]: 'Withdrawn',
                [LotActivityStatus.Removed]: 'Removed',
                [LotActivityStatus.Sold]: 'Sold',
                [LotActivityStatus.SoldPostAuction]: 'Lot sold',
                [LotActivityStatus.PassedIn]: 'Passed in',
                [LotActivityStatus.NotSold]: 'Not sold',
                [LotActivityStatus.Started]: 'Starting in %auctionTime%',
                [LotActivityStatus.NowSelling]: 'Selling',
                [LotActivityStatus.Paused]: 'Paused',
                [LotActivityStatus.ResumeDelayed]: 'Starting in %auctionTime%',
                [LotActivityStatus.FinishDelayed]: 'Finishing',
                [LotActivityStatus.StartDelayPaused]: 'Paused',
                [LotActivityStatus.WithdrawnNotSold]: 'Withdrawn',
                [LotActivityStatus.OfferManagement]: 'Pending Pick',
                [LotActivityStatus.UnderOffer]: 'Pending Pick'
            }
        },
        ['lotType' + LotType.Runout]: {
            [LotActivityStatus.Inactive]: 'Inactive',
            [LotActivityStatus.Active]: 'Active',
            [LotActivityStatus.Withdrawn]: 'Withdrawn',
            [LotActivityStatus.Removed]: 'Removed',
            [LotActivityStatus.Sold]: 'Lot sold',
            [LotActivityStatus.SoldPostAuction]: 'Lot sold post auction',
            [LotActivityStatus.PassedIn]: 'Lot passed in',
            [LotActivityStatus.NotSold]: 'Lot not sold',
            [LotActivityStatus.Started]: 'Starting in %auctionTime%',
            [LotActivityStatus.NowSelling]: 'Now selling',
            [LotActivityStatus.Paused]: 'Paused',
            [LotActivityStatus.ResumeDelayed]: 'Starting in %auctionTime%',
            [LotActivityStatus.FinishDelayed]: 'Finishing',
            [LotActivityStatus.StartDelayPaused]: 'Paused',
            [LotActivityStatus.WithdrawnNotSold]: 'Withdrawn not sold',
            [LotActivityStatus.OfferManagement]: 'Pending',
            [LotActivityStatus.UnderOffer]: 'Pending',
            mobile: {
                [LotActivityStatus.Inactive]: 'Inactive',
                [LotActivityStatus.Active]: 'Active',
                [LotActivityStatus.Withdrawn]: 'Withdrawn',
                [LotActivityStatus.Removed]: 'Removed',
                [LotActivityStatus.Sold]: 'Sold',
                [LotActivityStatus.SoldPostAuction]: 'Lot sold',
                [LotActivityStatus.PassedIn]: 'Passed in',
                [LotActivityStatus.NotSold]: 'Not sold',
                [LotActivityStatus.Started]: 'Starting in %auctionTime%',
                [LotActivityStatus.NowSelling]: 'Selling',
                [LotActivityStatus.Paused]: 'Paused',
                [LotActivityStatus.ResumeDelayed]: 'Starting in %auctionTime%',
                [LotActivityStatus.FinishDelayed]: 'Finishing',
                [LotActivityStatus.StartDelayPaused]: 'Paused',
                [LotActivityStatus.WithdrawnNotSold]: 'Withdrawn',
                [LotActivityStatus.OfferManagement]: 'Pending',
                [LotActivityStatus.UnderOffer]: 'Pending'
            }
        }
    },

    statusColor: {
        [LotActivityStatus.Started]: '#0091FF',
        [LotActivityStatus.ResumeDelayed]: '#0091FF',
        [LotActivityStatus.Sold]: '#3C4C54',
        [LotActivityStatus.PassedIn]: '#3C4C54',
        [LotActivityStatus.NotSold]: '#3C4C54',
        [LotActivityStatus.FinishDelayed]: '#3C4C54',
        [LotActivityStatus.WithdrawnNotSold]: '#3C4C54'
    },
    timerMessage: {
        timeExtended: 'Extended %auctionTime%'
    },
    currentPriceMessage: {
        perUnit: 'Current Price / %incrementUnits%'
    },
    reserveStatus: {
        [ReserveStatus.None]: 'Reserve not met',
        [ReserveStatus.NearReserve]: 'Near reserve',
        [ReserveStatus.ReserveMet]: 'Reserve met',
        [ReserveStatus.NoReserve]: 'No Reserve'
    },
    reserveStatusColor: {
        [ReserveStatus.NearReserve]: '#0091FF',
        [ReserveStatus.ReserveMet]: '#81C341'
    },
    userBidMessage: {
        lead: 'You lead',
        outbid: 'Out bid'
    },
    userBidMessageColor: {
        lead: '#3C4C54',
        outbid: '#E02020'
    },
    bidButtonText: 'Bid',
    bidInputPlaceholder: 'Amount',
    noAccountMessage: 'Bidding is only available to registered buyers.',
    lostConnectionMsg: 'Lost connection with auction, Reconnecting...',
    incrementError: 'Bid must be higher than the next increment amount.',
    allowSounds: 'Allow sounds to play on iOS',
    lotMetrics: {
        pricePerKg: 'Price / kg',
        pricePerHeadEquivalent: 'Price per head equiv.',
        totalPrice: 'Total Price',
        agent: 'Agent / Agency',
        agency: 'Agency',
        aveWeight: 'Avg Weight',
        withholding: 'In Withholding',
        location: 'Location',
        stockType: 'Stock type',
        tally: 'Tally',
        trueWithholding: 'Withholding Period Applies',
        mobileWithholding: 'Period Applies',
        age: 'Age',
        breed: 'Breed',
        tbStatus: 'TB Status',
        vendorName: 'Vendor Name',
        description: 'Comments'
    },
    sounds: {
        lotDetailChange: 'lot-detail-change',
        auctionNotification: 'auction-notification',
        bidOutbid: 'bid-outbid',
        bidPlaced: 'bid-placed',
        bidWin: 'bid-win'
    },
    marketOperator: {
        loading: 'Loading...',
        save: 'Save',
        cancel: 'Cancel',
        tally: 'Tally',
        aveWeight: 'Weight',
        startPrice: 'Start Price',
        reservePrice: 'Reserve Price',
        lotIncrement: 'Lot Increment per %incrementUnits%',
        timeExtension: '+ Time Extension',
        noTimeExtension: '+ No Time Extension',
        expireExtend: '+ Expire & Extend',
        expireExtendSet: 'Expire & Extend Set',
        currentPrice: 'Current Price /%currentPrice%',
        setNearReserve: 'Set to Near Reserve',
        setLotClosing: 'Set to Lot Closing',
        removeLotClosing: 'Remove Lot Closing Message',
        lotClosing: 'Lot Closing',
        lotNowSelling: 'Now Selling',
        lotFinishing: 'Finishing',
        closeLot: 'Close Lot',
        floorBid: 'Floor Bid',
        durationControls: 'Duration Controls',
        lotDuration: 'Lot Duration',
        lotStartDuration: 'Lot Start Duration',
        lotFinishDuration: 'Lot Finish Duration',
        durationAfterPause: 'Duration After Pause',
        autoExtensionControls: 'Auto-extension Controls',
        lotExtensionDuration: 'Lot Extension Duration',
        timeExtensionTrigger: 'Time Extension Trigger',
        timeControlNotAvailable: '(not available for HYBRID auctions)'
    }
}

export const colours = {
    lotClosingColour: '#D8400E',
    lotNowSellingColour: '#81C341'
}
